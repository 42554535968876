<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <g-form
                    class="popup-dialog"
                    :form="form"
                    :action="`/api/admin/transaction/${this.id}/cancel`"
                    :prepopulate="true"
                    @success="this.destroy"
                >
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('admin.transactions.cancel.title') }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body">
                        <div class="notification is-danger">
                            <small>{{ $t('admin.transactions.cancel.should_be_handled_by_application_warning') }}</small>
                        </div>


                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.cancel.id') }}</strong>
                            </div>
                            <div class="column">{{ id }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{
                                    $t('admin.transactions.cancel.amount')
                                }}</strong></div>
                            <div class="column">{{ form.amount / 100 | currency }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{
                                    $t('admin.transactions.cancel.type')
                                }}</strong></div>
                            <div class="column">{{ $t('admin.transactions.type_label.' + form.type) }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{
                                    $t('admin.transactions.cancel.date')
                                }}</strong></div>
                            <div class="column">{{ $i18nDate(new Date(form.created_at), 'long') }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{
                                    $t('admin.transactions.cancel.description')
                                }}</strong></div>
                            <div class="column">{{ form.description }}</div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" role="button">{{
                                $t('admin.transactions.cancel.cancel')
                            }}</a>
                        <g-button>{{ $t('admin.transactions.cancel.reverse') }}</g-button>
                    </div>
                </g-form>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "TransactionReverseForm",
    data() {
        return {
            form: new Form({
                amount: null,
                type: null,
                created_at: null,
                description: null,
            })
        }
    },
    props: {
        id: {
            type: Number,
        },
    },
}
</script>

<style scoped>

</style>
