<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @loading-state="setLoadingState"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-fifth"><label>Persoon</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="first_name" label="Voor- en doopnamen" :disabled="isVerified"/>
                                        <g-input name="last_name" label="Achternaam" :disabled="isVerified"/>
                                    </div>
                                    <g-format type="date" name="dob" label="Geboortedatum" :disabled="isVerified"/>

                                    <b-notification v-if="isVerified" :closable="false">
                                        <small>We hebben deze persoon al gevalideerd. Neem contact
                                            met ons op om wijzigingen door te geven.</small>
                                    </b-notification>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>Woonadres</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="street" label="Straatnaam"/>
                                        <g-input name="house_number" label="Huisnummer"/>
                                        <g-input name="addition" label="Toevoeging"/>
                                    </div>

                                    <div class="field-body">
                                        <g-input name="zip" label="Postcode"/>
                                        <g-input name="city" label="Plaats"/>
                                    </div>

                                    <div class="field-body">
                                        <g-select name="country_id" :options="this.countries"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>Functies</label></div>
                                <div class="column">
                                    <g-switch name="is_representative" label="Vertegenwoordiger"
                                              :disabled="!allowRepresentativeEdit"/>
                                    <g-switch name="is_executive" label="Manager / Directeur"
                                              :disabled="isRepresentative"/>
                                    <g-switch name="is_owner" label="Aandeelhouder (25% of meer)"/>

                                    <g-input name="owner_percentage" v-if="isOwner" label="Percentage aandelen"
                                             type="number" min="0" max="100"/>
                                </div>
                            </div>

                            <div class="columns" v-if="verificationRequired">
                                <div class="column is-one-fifth"><label>Legitimatie</label></div>
                                <div class="column">
                                    <g-file name="verification_document_front"
                                            label="Voorkant legitimatiebewijs" accept="image/*"/>
                                    <g-file name="verification_document_back"
                                            label="Achterkant legitimatiebewijs" accept="image/*"/>
                                </div>
                            </div>

                            <div class="columns" v-if="verificationAdditionalRequired">
                                <div class="column is-one-fifth"><label>Aanvullende legitimatie</label></div>
                                <div class="column">
                                    <b-message size="is-small">
                                        Een extra document ter verificatie waarop het woonadres zichtbaar is.
                                        Bijvoorbeeld een telefoon- of energierekening.
                                    </b-message>

                                    <g-file name="additional_document_front"
                                            label="Voorkant extra legitimatiebewijs" accept="image/*"/>
                                    <g-file name="additional_document_back"
                                            label="Achterkant extra legitimatiebewijs" accept="image/*"/>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button :loading="formLoading">Opslaan</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import {mapGetters} from "vuex";

export default {
    props: {
        id: String,
        entities: Array
    },
    data() {
        return {
            entity: this.id ? this.$store.getters['entities/getById'](this.id) : null,
            form: null,
            formLoading: false,
            statusFieldMap: {
                verification_document: 'verification_document_front',
                verification_additional_document: 'additional_document_front',
            },
            statusWarningMap: {
                first_name: 'Geef een voornaam op',
                last_name: 'Geef een achternaam op',
                city: 'Geef een woonplaats op',
                zip: 'Geef een postcode op',
                street: 'Geef een straat op',
                dob: 'Geef een geboortedatum op',
                verification_document_front: 'Geef een legitimatiebewijs op in PNG of JPG',
                additional_document_front: 'Geef een extra legitimatiebewijs op in PNG of JPG',
            },
        }
    },
    created() {
        this.$store.dispatch('application/loadAllCountries')

        let formFields = {
            first_name: this.entity ? this.entity.first_name : null,
            last_name: this.entity ? this.entity.last_name : null,
            street: this.entity ? this.entity.street : '',
            house_number: this.entity ? this.entity.house_number : '',
            addition: this.entity ? this.entity.addition : '',
            zip: this.entity ? this.entity.zip : '',
            city: this.entity ? this.entity.city : '',
            country_id: this.entity ? this.entity.country_id : this.$store.getters['user/activeCompany'].country_id,
            dob: this.entity ? this.formatDateToHuman(this.entity.dob) : '',
            is_representative: this.entity ? this.entity.is_representative : 0,
            is_executive: this.entity ? this.entity.is_executive : 0,
            is_owner: this.entity ? this.entity.is_owner : 0,
            owner_percentage: this.entity ? this.entity.owner_percentage : null,
            verification_document_front: null,
            verification_document_back: null,
            additional_document_front: null,
            additional_document_back: null,
        }

        if (this.entity) {
            formFields.id = this.entity.id
        }

        this.form = new Form(formFields)
    },
    computed: {
        ...mapGetters({
            countries: 'application/availableCountriesTranslated'
        }),
        allowRepresentativeEdit() {
            if (this.companyHasRepresentative) {
                return this.entity ? !!this.entity.is_representative : false
            }

            return true
        },
        companyHasRepresentative() {
            return this.$store.getters['entities/getByRole']('is_representative').length !== 0
        },
        isRepresentative() {
            return !!this.form.is_representative
        },
        isOwner() {
            return !!this.form.is_owner
        },
        isCreateNewForm() {
            return this.entity == null
        },
        isVerified() {
            if (this.isCreateNewForm) {
                return false
            }

            return !!this.entity.is_verified
        },
        verificationRequired() {
            return this.fieldRequired('verification_document_front')
        },
        verificationAdditionalRequired() {
            return this.fieldRequired('additional_document_front')
        },
        formTitle() {
            return this.isCreateNewForm ? 'Persoon toevoegen' : 'Persoon aanpassen'
        },
        formEndpoint() {
            let endpoint = `/api/company/${this.$store.getters['user/activeCompanyId']}/entity`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        },
    },
    watch: {
        'form.is_representative'(value) {
            if (0 === value) {
                return
            }

            this.form.is_executive = value // A representative should always have executive rights
        },
        'form.is_owner'(value) {
            if (1 === value) {
                return
            }

            this.form.owner_percentage = null
        }
    },
    mounted() {
        this.setErrors()
    },
    methods: {
        fieldRequired(field) {
            if (false === this.isCreateNewForm) {
                for (let error in this.entity.status_details) {
                    const fieldName = this.statusFieldMap[this.entity.status_details[error].value]

                    if (field === fieldName) {
                        return true
                    }
                }
            }

            return false
        },
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'entities/add' : 'entities/update'

            this.$store
                .dispatch(action, {
                    entity: response.data
                })
                .then(this.destroy)
        },
        formatDateToHuman(date) {
            if (typeof date === 'undefined') return null

            const format = moment(date).format('DD-MM-YYYY')
            return format === 'Invalid date' ? '' : format
        },
        formatHumanToDate(date) {
            const format = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            return format === 'Invalid date' ? '' : format
        },
        formatHumanToISO(date) {
            const format = moment(date, 'DD-MM-YYYY').toISOString()
            return format === 'Invalid date' ? '' : format
        },
        formatData(form) {
            // Check if the new entity exists yet
            if (this.isCreateNewForm === true) {
                const firstName = form.first_name ? form.first_name.toLowerCase() : ''
                const lastName = form.last_name ? form.last_name.toLowerCase() : ''

                const new_entity = firstName + lastName + moment(form.dob, 'DD-MM-YYYY').format('YYYY-MM-DD')
                for (let i = 0; i < this.entities.length; i++) {

                    const existing_entity = this.entities[i].first_name.toLowerCase() + this.entities[i].last_name.toLowerCase() + moment(this.entities[i].dob).format('YYYY-MM-DD')

                    if (new_entity === existing_entity) {
                        this.destroy()
                        this.$buefy.dialog.alert(`<b>${form.first_name} ${form.last_name}</b> staat al tussen de aandeelhouders en managers.`)

                        return
                    }
                }
            }

            form.dob = this.formatHumanToDate(form.dob)
            return form
        },
        setErrors() {
            if (false === this.isCreateNewForm) {
                let errors = {}

                for (let error in this.entity.status_details) {
                    const field = this.statusFieldMap[this.entity.status_details[error].value] || this.entity.status_details[error].value
                    errors[field] = [this.statusWarningMap[field]]
                }

                this.form.setErrors(errors)
            }
        },
        setLoadingState(state) {
            this.formLoading = state;
        },
    },
}
</script>
