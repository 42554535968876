import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../lang/en'
import nl from '../lang/nl'

Vue.use(VueI18n)

export default new VueI18n({
    locale: localStorage.getItem('locale') || 'nl',
    fallbackLocale: 'en',
    messages: {
        en,
        nl,
    },
})
