import Vue from 'vue'
import Vuex from 'vuex'
import Buefy, {ConfigProgrammatic} from 'buefy'
import store from './store'
import Form from './utils/Form'
import router from './utils/Router'
import i18n from './utils/i18n'
import moment from 'moment-timezone'
import VueScrollTo from 'vue-scrollto'
import posthog from 'posthog-js'
import App from './views/App.vue'
import VueCurrencyFilter from 'vue-currency-filter'
import * as Sentry from '@sentry/vue'
import {BrowserTracing} from "@sentry/tracing";
import VueSimpleMarkdown from 'vue-simple-markdown'
import './utils/ComponentRegister'
import './components/Popup'
import './filters/filters'
import localDateUtil from "./utils/DateTime";

window.axios = require('axios')
window.moment = moment
window.Form = Form
window.moment.locale('nl')

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://57e90ca2bf704efb9a5d60d3dc55e67d@o109994.ingest.sentry.io/4504537158057984',
        release: process.env.MIX_BUILD_NUMBER,
        environment: process.env.APP_ENV,
        logErrors: true,
        tracesSampleRate: 0.3,
        tracingOptions: {
            trackComponents: true,
        },
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [process.env.APP_URL, /^\//],
            }),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    })

    posthog.init('phc_kMPiu1ZUB5We1WLu9X0YOCo8FEzQtiwGik9CAMXw8G7', {
        api_host: 'https://eu.posthog.com',
        property_blacklist: [
            'password',
            'password_confirmation',
            'card_number',
            'card_cvc',
            'card_expiry',
            'giftcard',
            'gift_card',
            'giftCard',
            'code'
        ],
    })
}

Vue.use(Buefy)
Vue.use(VueCurrencyFilter, {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.use(Vuex)
Vue.use(VueScrollTo)
Vue.use(VueSimpleMarkdown)
Vue.prototype.$i18nDate = localDateUtil

new Vue({
    el: '#gifty',
    template: '<App/>',
    components: {
        App: App,
    },
    store,
    router,
    i18n,
    mounted() {
        window.axios.interceptors.request.use((config) => {
            // For each request, we set common headers
            config.headers['X-Requested-With'] = 'XMLHttpRequest'
            config.headers['X-Client-Application'] = `Gifty Dashboard ${process.env.MIX_BUILD_NUMBER}`
            config.headers['Accept-Language'] = this.$i18n.locale

            // Append X-Gifty-Account header if the activeCompanyId key is set
            if (localStorage.hasOwnProperty('activeCompanyId')) {
                config.headers['X-Gifty-Account'] = localStorage.getItem('activeCompanyId')
            }

            // We set a cancelToken, so we can cancel running requests. Except POST requests,
            // those should always continue.
            if (config.method !== "POST" && config.method !== "post") {
                config.cancelToken = window.axios.CancelToken.source().token
            }

            return config
        })

        window.axios.interceptors.response.use(function (response) {
            return response
        }, async function (error) {
            if (typeof error.response === 'undefined') {
                console.info('Interceptor: no response data, so rejecting promise')

                return Promise.reject(error)
            }

            // Most likely expected form validation error
            if (error.response.status === 422) {
                console.info('Interceptor: seems like form error, so rejecting promise')

                return Promise.reject(error)
            }

            // Session expired
            if (error.response.status === 401) {
                console.info('Interceptor: expired session')

                window.axios.CancelToken.source().cancel('session expired');

                store.dispatch('user/logout')
                    .finally(() => {
                        if (router.currentRoute.name !== 'auth.login') {
                            router.push({name: 'auth.login'})
                        }
                    })
            }

            // Missing required permissions
            if (error.response.status === 403) {
                console.info('Interceptor: wrong permissions, redirecting to home')

                window.axios.CancelToken.source().cancel('invalid permissions');

                this.$buefy.toast.open('Je hebt niet de juiste rechten om deze pagina te bekijken')

                await router.push({name: 'home'})
            }

            // CSFR token mismatch, reload page
            if (error.response.status === 419) {
                console.info('Interceptor: CSFR expired, reloading page')

                window.axios.CancelToken.source().cancel('CSFR expired');

                await location.reload()
            }

            return Promise.reject(error)
        })

        ConfigProgrammatic.setOptions({
            defaultDialogConfirmText: this.$t('confirmation_dialog.confirm'),
            defaultDialogCancelText: this.$t('confirmation_dialog.deny'),
            defaultFirstDayOfWeek: moment.localeData().firstDayOfWeek(),
            defaultDayNames: moment.localeData().weekdaysShort(),
            defaultMonthNames: moment.localeData().months(),
            defaultDateFormatter: date => moment(date).format('DD-MM-YYYY')
        })
    },
})
