export default function localDateUtil(date = new Date(), formatting = 'numeric', locale) {
    // Check if the provided formatting option is valid
    const formattingOptions = ['numeric', 'numericDate', 'short', 'long']
    if (formattingOptions.includes(formatting) === false) {
        throw new Error('Invalid formatting option provided.')
    }

    // Cast the date to a Date object if it's not already
    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    // When no locale is provided, we'll try to use the locale from the localStorage
    if (locale === undefined) {
        locale = localStorage.getItem('locale') || 'nl'
    }

    // To prevent the browser from using the US date format, we force the GB date format
    if (locale.toLowerCase() === 'en') {
        locale = 'en-GB'
    }

    // Formatting options for the different locales
    const options = {
        'en-GB': {
            numeric: {
                year: 'numeric', month: '2-digit', day: '2-digit',
                hour: '2-digit', minute: '2-digit'
            },
            numericDate: {
                year: 'numeric', month: '2-digit', day: '2-digit'
            },
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric', month: 'short', day: 'numeric',
                weekday: 'short', hour: 'numeric', minute: 'numeric'
            }
        },

        'nl': {
            numeric: {
                year: 'numeric', month: '2-digit', day: '2-digit',
                hour: '2-digit', minute: '2-digit'
            },
            numericDate: {
                year: 'numeric', month: '2-digit', day: '2-digit'
            },
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric', month: 'short', day: 'numeric',
                weekday: 'short', hour: 'numeric', minute: 'numeric'
            }
        },
    }

    return new Intl.DateTimeFormat(locale, options[locale][formatting] || {}).format(date)
}
